import NonLoggedPageTemplate from '../modules/messagesApp/layout/NonLoggedPageTemplate';

export const DocsPage = () => (
  <NonLoggedPageTemplate contentWidth="90vw">
    <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <iframe
        title="Vifzack documentation"
        style={{ backgroundColor: 'white' }}
        src="https://instea.slab.com/posts/vifzack-user-docs-draft-dgr8xzlb?shr=RdrzkkYoPA9QGef7gvB8KhWU"
        width="100%"
        height="75%"
      />
    </div>
  </NonLoggedPageTemplate>
);
