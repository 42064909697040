import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import classNames from 'classnames';
import { FieldInputProps, useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { ReactNode, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useResponsiveQueries } from '../../common/utils/layoutUtils';
import { getLocaleData } from '../../config/intl/intl';
import { getLocale } from '../../modules/settings/settingsReducer';
import style from './RichEditor.module.less';
import {
  RichEditorImageUploadFunction,
  RichEditorUploadPlugin,
} from './RichEditorPlugins';

// type equivalent of @ckeditor/ckeditor5-engine -> DataController
// It cannot be imported directly as we use custom build of ckeditor
type DataController = {
  get: () => string;
  set: (val: string) => void;
};

export type RichTextEditorValue = DataController | string;

function isDataController(
  value?: RichTextEditorValue
): value is DataController {
  return (value as DataController)?.get !== undefined;
}

export function richTextEditorValueToString(value?: RichTextEditorValue) {
  if (isDataController(value)) {
    return value?.get();
  }
  return value;
}

export type RichTextEditorProps = FieldInputProps<RichTextEditorValue> & {
  mode?: RichEditorMode;
  uploadFunction?: RichEditorImageUploadFunction;
  isInFlex?: boolean;
  children?: ReactNode;
  autofocus?: boolean;
  bordered?: boolean;
  disabled?: boolean;
};

export enum RichEditorMode {
  Simple = 'simple',
  SimpleNoImage = 'simpleNoImage',
  Advanced = 'advanced',
}

const Configs: { [key in RichEditorMode]: any } = {
  [RichEditorMode.Advanced]: {
    toolbar:
      `heading | fontfamily fontsize fontColor | bold italic underline alignment | link uploadImage
       | bulletedList numberedList indent outdent | codeBlock blockQuote | undo redo`.split(
        /\s+/g
      ),
    extraPlugins: [RichEditorUploadPlugin],
    htmlSupport: {
      allow: [
        { name: 'div', styles: true, classes: true, attributes: true },
        {
          name: 'details',
          attributes: {
            'data-role': 'email-editor-quote',
          },
        },
        {
          name: 'summary',
          classes: true,
          attributes: {
            'data-role': 'email-editor-quote',
            contenteditable: true,
          },
        },
      ],
    },
  },
  [RichEditorMode.Simple]: {
    toolbar:
      'fontfamily fontsize fontColor | bold italic underline alignment | link uploadImage | undo redo'.split(
        /\s+/g
      ),
    extraPlugins: [RichEditorUploadPlugin],
    image: {
      insert: {
        type: 'inline',
      },
    },
  },
  [RichEditorMode.SimpleNoImage]: {
    toolbar:
      'fontfamily fontsize fontColor | bold italic underline alignment | undo redo'.split(
        /\s+/g
      ),
    extraPlugins: [RichEditorUploadPlugin],
  },
};

export function RichTextEditor({
  mode = RichEditorMode.Advanced,
  name,
  uploadFunction,
  isInFlex,
  autofocus = false,
  bordered = false,
  children,
  disabled,
}: RichTextEditorProps) {
  const ref = useRef<HTMLDivElement>(null);
  const media = useResponsiveQueries();
  const { setFieldValue, initialValues, setFieldTouched } = useFormikContext();
  const locale = useSelector(getLocale);
  const { language } = getLocaleData(locale);

  const defaultValue = get(initialValues, name);

  return (
    <div
      ref={ref}
      className={classNames(
        style.Container,
        bordered && style.ContainerBordered,
        isInFlex && style['Container--Flex1']
      )}
    >
      <CKEditor
        editor={ClassicEditor}
        data={defaultValue}
        disabled={disabled}
        onBlur={() => {
          setFieldTouched(name, true);
        }}
        onReady={(editor: ClassicEditor) => {
          // `editor.data` is a mutable object therefore it is sufficient to set it once
          setFieldValue(name, editor.data);
          if (autofocus) {
            ref.current?.scrollIntoView();
            editor.editing.view.focus();
          }
          editor.editing.view.document.on(
            'enter',
            (evt, data) => {
              editor.execute('shiftEnter');
              //Cancel existing event
              data.preventDefault();
              evt.stop();
            },
            { priority: 'high' }
          );
        }}
        config={{
          ...Configs[mode],
          toolbar: media.lg ? Configs[mode].toolbar : [],
          language: language.toLowerCase(),
          imageUploadFunction: uploadFunction,
        }}
      />
      {children}
    </div>
  );
}
